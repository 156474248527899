import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  DatePicker,
  Tooltip,
  Collapse,
  InputNumber,
  Radio,
  Spin,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import apiClient from "../../helpers/ApiClientCF";
import APIClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../Siteadmin/Siteadmin.scss";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import CashfreePayment from "../../helpers/CashfreeApi";
// import { CashFreePay } from "../../helpers/CashfreeApi";
import CashFreePay from "../../helpers/CashfreeApi";
import { useAuthContext } from "../../common/providers/AuthProvider";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import SessionTimeOutModal from "../../common/SessionTimeOutModal/SessionTimeOutModal";
import CashFreeNewVersionCheckout from "../../helpers/CashFreeNewVersionCheckout";
import queryString from "query-string";
const { Content } = Layout;
const { Panel } = Collapse;
const Deposits = () => {
  const { user } = useAuthContext();
  const { Option } = Select;
  const [searchForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const [revokeForm] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [activePanel, setActivePanel] = useState(["1"]);
  const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false);
  const [isDepositeSubmitLoading, setIsDepositeSubmitLoading] = useState(false);
  const [showcash, setCashBox] = useState(false);
  const [Val, setVal] = useState([]);
  const [openCashfreeNewVersionCheckout, setOpenCashfreeNewVersionCheckout] = useState(false)
  const [pgData, setPgData] = useState({});
  const [PaymentType, setPaymentType] = useState(null);

  let staticObj = {
    filter: {
      // userId: user ? user.id : null,
      userId: 2,
    },
    page: 0,
  };
  useEffect(() => {
    getReportsList(staticObj);
    getPgdetails();
    getqueryparam();
  }, []);
  const getqueryparam = () => {
    const params = queryString.parse(document.location.search);
    if (params.Status === "Success") {
      message.success("TopUp is successful", 3);
    } else if (params.Status === "Failed") {
      message.error("TopUp is Failed", 3);
    }
  };
  const getReportsList = (obj) => {
    ApiClient.post("account/getdepositereports", obj)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setReportsList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateDepositStatus = (val) => {
    ApiClient.post("account/depositstatusrequest", val).then((resp) => {
      /** res will be return undefined if the API is UnAuthorize   */
      if (resp == "TypeError: Failed to fetch") {
        setIsSessionTimeOutModel(true)
      }
      if (resp.isSuccess) {
        getReportsList(staticObj);
        message.success("Reports Updated successfully", 3);
        setCurrentId(-1);
      } else {
        message.error("Reports not Updated", 3);
      }
    });
  };

  const submitForm = (val, id, depositeId) => {
    let depositId = id;
    let data = {
      depositeId: depositId,
      approvalStatus: val[`approvalStatus_${depositeId}`],
      remarks: val[`remarks_${depositeId}`],
    };
    updateDepositStatus(data);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <Form
        layout="vertical"
        name={`form_${currentRecord.depositeId}`}
        onFinish={(val) =>
          submitForm(val, currentRecord.userId, currentRecord.depositeId)
        }
        style={{ minWidth: "400px" }}
        autoComplete="off"
      >
        <Row gutter={12} align="middle">
          <Col md={9} xs={24}>
            <Form.Item
              label="Status"
              name={`approvalStatus_${currentRecord.depositeId}`}
              rules={[{ required: true, message: "Please select Status" }]}
            >
              <Select placeholder="Please select Service Name">
                <Option value="Approved">Approved</Option>
                <Option value="Rejected ">Rejected </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={9} xs={24}>
            <Form.Item
              label="Remarks"
              name={`remarks_${currentRecord.depositeId}`}
              rules={[{ required: true, message: "Please enter Remarks" }]}
            >
              <Input
                autocomplete="newpassword"
                placeholder="Please Enter Remarks"
              />
            </Form.Item>
          </Col>
          <Col md={3} xs={24}>
            <Button key="add" type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
      //   </div>
      //   {/* <div className="edit-icon" style={{ textAlign: "center" }}>
      //     <Popconfirm
      //       title="Are you sure want to delete?"
      //       onConfirm={() => deleteSuppliers(Name)}
      //     >
      //       <DeleteOutlined
      //         style={{ color: "red", fontSize: "20px", textAlign: "center" }}
      //       />
      //     </Popconfirm>
      //   </div> */}
      // </div>
    );
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  // const getStatus = (item) => {
  //   if (item === "Approved") {
  //     return "";
  //   }
  // };

  const columns = [
    //{
    // title: "Sr. No.",
    //dataIndex: "SNo",
    //sorter: (a, b) => a.SNo - b.SNo,
    //},
    {
      title: "Deposit ",
      dataIndex: "depositeId",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "DateTime",
      dataIndex: "depositeDate",
      sorter: (a, b) => a.depositeDate.localeCompare(b.depositeDate),
      render: (depositeDate) => moment(depositeDate).format("DD-MM-YYYY")

    },
    {
      title: "Status",
      dataIndex: "approvalStatus",
      // render: (item) => getStatus(item),
      sorter: (a, b) => a.approvalStatus.localeCompare(b.approvalStatus),
    },
    {
      title: "CompanyName",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      sorter: (a, b) => a.paymentMode.localeCompare(b.paymentMode),
    },
    {
      title: "RefNo",
      dataIndex: "referenceNumber",
      sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
    },
    {
      title: "TrxnId",
      dataIndex: "transactionId",
      sorter: (a, b) => a.transactionId - b.transactionId,
    },
    // {
    //   title: "Actions",
    //   render: (rec, name) =>
    //     rec.approvalStatus === "Approved" ? null : tableActions(rec, name),
    // },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(reportsList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(reportsList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      DateTime: moment(item.depositeDate).format("DD-MM-YYYY"),
      DepositeId: item.depositeId,
      Amount: item.amount,
      ApprovalStatus: item.approvalStatus,
      CompanyName: item.companyName,
      PaymentMode: item.paymentMode,
      ReferenceNumber: item.referenceNumber,
      Remarks: item.remarks,
      TransactionId: item.transactionId,
    }));
    exportExcel(excelData, "Deposits Reports");
  };
  const searchReportsForm = (val) => {
    let obj = {
      filter: {
        fromDate: val.fromDate,
        toDate: val.toDate,
        userId: 2,
        approvalStatus: val.approvalStatus,
      },
      page: 0,
    };
    if (
      !obj.filter.fromDate &&
      !obj.filter.toDate &&
      !obj.filter.userId &&
      !obj.filter.approvalStatus
    ) {
      delete obj.filter;
    }
    getReportsList(obj);
  };

  const addBalanceForm = async (val) => {
    setIsDepositeSubmitLoading(true)
    if (selectedPaymentMode != "INTOPUP") {
      val.depositeDate = moment(val.depositeDate).format("YYYY-MM-DD")
      ApiClient.post("account/depositrequest", val).then((resp) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (resp == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (resp.isSuccess) {
          message.success("Deposit Request Sent successfully", 3);
          setCurrentId(-1);
          addForm.resetFields();
          setIsDepositeSubmitLoading(false)
        } else {
          message.error("Failed", 3);
        }
      });
    } else {
      // setIsDepositeSubmitLoading(false);
      // console.log(val, "cash");
      createPaymentLink(val);
      // CashfreePayment(val, user, totalAmount);
      setCashBox(true);
      setVal(val);
      setIsDepositeSubmitLoading(false);
    }
  };

  const createPaymentLink = async (val) => {

    try {
      let request = {
        "ampunt": amount,
        "userid": user.id,
        "remarks": val.remarks,
        "servicecharge": Number(tdrAmount + tdrGST),
        "paymenttype": val.DepositType
      }
      // console.log(request, tdrGST, tdrAmount + tdrGST, "resu-123");
      ApiClient.post("account/instanttopup", request).then((res) => {

        // if(res === null){
        setPgData(res?.data)
        setOpenCashfreeNewVersionCheckout(true)
        // }
        // setTimeout(() => {

        //   console.log(res, "resu-123");
        // },10000);



      }).catch((err) => {
        console.log(err, "post-err");
      })

      // console.log('Payment Link Created:', res);
    } catch (error) {
      console.error('Error creating payment link:', error);
    }
  };

  // instant recharge

  const [amount, setAmount] = useState(0);
  const [tdrAmount, setTdrAmount] = useState(0);
  const [tdrGST, setGSTAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [Pgselecteddata, setPgselecteddata] = useState({});
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
  const [pgList, setPgList] = useState([]);

  const handlePaymentModeChange = (value) => {
    setSelectedPaymentMode(value);
    setPgselecteddata({});
    setTotalAmount(0);
    setTdrAmount(0);
  };
  const handlePG = (value) => {
    setPaymentType(value);
    var data = pgList.find(x => x.paymentName == value);
    // console.log(data, pgList, value, "data2");
    setPgselecteddata(data);
    changeTdrValues(data, amount);

  };
  const getPgdetails = () => {
    APIClient.get("account/getpaymenttypes")
      .then((res) => {
        if (res.isSuccess) {
          setPgList(res.data);
        }
      })
      .catch(() => {
        setPgList([]);
      });
  };
  const changeTdrValues = (data, amount) => {
    // console.log("venu", data, amount);
    if (data && Object.keys(data).length > 0) {
      let tdrAmount =
        data.type == "percentage"
          ? (data.amount / 100) * amount
          : parseInt(data.amount, 10);
      let GSTonTdr = Number(tdrAmount * 0.18);

      setGSTAmount(GSTonTdr);
      // setGSTAmount(Number(tdrAmount * 0.18))

      let totalAmount =
        data.type == "percentage"
          ? (data.amount / 100) * amount + parseInt(amount, 10) + parseInt(GSTonTdr, 10)
          : parseInt(data.amount, 10) + parseInt(amount, 10) + parseInt(GSTonTdr, 10);

      setTdrAmount(Number(tdrAmount));

      // console.log(tdrAmount, tdrGST, totalAmount, "ant-123");
      setTotalAmount(Number(totalAmount).toFixed(2));
    }
    else {

      setTdrAmount(0);
      setTotalAmount(amount);
    }
  };
  const handleAmountChange = (e) => {
    if (e > 0) {
      // if (Number(e?.target.value) > 0) {
      //   setAmount(e?.target.value);
      //   changeTdrValues(Pgselecteddata, Number(e?.target.value));
      // } else {
      setAmount(e);
      changeTdrValues(Pgselecteddata, e);
    }
    else {
      setAmount(0);
      changeTdrValues(Pgselecteddata, 0);
    }
  };
  const disabledFromDate = (current) => {

    // return current && current > moment().endOf('day');

    return current.valueOf() <= Date.now();
  }
  const getPaymentType = (key) => {
    switch (key) {
      case 1:
        return "Debit Card";
      case 2:
        return "Credit Card";
      case 3:
        return "Net Banking";
      case 4:
        return "UPI";
      // case 5:
      //   return "Amex";
      default:
        return;
    }
  };
  function chooseTitle(res) {
    let result = "";
    if (res == 1) {
      result += "DebitCard";
    }
    if (res == 2) {
      result += "CreditCard";
    }
    if (res == 3) {
      result += "NetBanking";
    }
    if (res == 4) {
      result += "UPI";
    }
    // if (res == 5) {
    //   result += "Amex";
    // }
    return result;
  }
  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                      <CaretRightOutlined rotate={isActive ? 90 : -90} />
                    </Tooltip>
                  )}
                  expandIconPosition="right"
                  className="panel_wrapper"
                  defaultActiveKey={["1"]}
                  activeKey={activePanel}
                  onChange={(arrKeys) => {
                    if (arrKeys.length > 1) {
                      setActivePanel(arrKeys[arrKeys.length - 1]);
                    } else {
                      setActivePanel(arrKeys);
                    }
                  }}
                >
                  <Panel header="Deposit Request" key="1">
                    {" "}
                    <div className="card-bt-gap">
                      <Card bordered={false}>

                        {/* <div className="card-add-heading"> */}
                        <Form
                          layout="vertical"
                          name="addBalanceForm"
                          form={addForm}
                          onFinish={addBalanceForm}
                        >
                          <Row gutter={12} align="middle">
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                label="Payment Mode"
                                name="paymentMode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Payment Mode",
                                  },
                                ]}
                              >
                                <Select placeholder="Please select Payment Mode"
                                  onChange={handlePaymentModeChange}
                                >
                                  {/* <Option value="VirtualCash">
                                      VirtualCash
                                    </Option>
                                    <Option value="Cheque ">Cheque </Option>
                                    <Option value="Transfers">Transfers</Option>
                                    <Option value="Imps">Imps</Option>
                                    <Option value="Neft">Neft</Option>
                                    <Option value="Credit ">Credit </Option> */}
                                  <Option value="INTOPUP">
                                    INSTANT TOP-UP
                                  </Option>
                                  <Option value="CashDepositATM">
                                    Cash Deposit ATM
                                  </Option>
                                  <Option value="CashDepositBank">
                                    Cash Deposit Bank
                                  </Option>
                                  <Option value="DirectNetTransfer">
                                    Direct NetTransfer
                                  </Option>
                                  <Option value="GooglePayTransfer">
                                    GooglePay Transfer
                                  </Option>
                                  <Option value="PhonePayTransfer">
                                    PhonePay Transfer
                                  </Option>
                                  <Option value="AmazonPayTransfer">
                                    AmazonPay Transfer
                                  </Option>
                                  <Option value="RTGS">
                                    RTGS (Real-Time Gross Settlement)
                                  </Option>
                                  <Option value="UPI">
                                    UPI (Unified Payments Interface)
                                  </Option>
                                  <Option value="AMC">
                                    AMC (Annual Maintenance Charges)
                                  </Option>
                                  <Option value="BusCancelRefund">
                                    Bus Cancel Refund
                                  </Option>
                                  <Option value="CancellationRefund">
                                    Cancellation Refund
                                  </Option>

                                  {/* <Option value="GSTCredit">
                                    GSTCredit
                                  </Option>
                                  <Option value="TDSCredit">
                                    TDSCredit
                                  </Option> */}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Amount",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (selectedPaymentMode === "INTOPUP" && value > 25000) {
                                        return Promise.reject(
                                          new Error("Amount cannot exceed 25,000")
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}


                              >
                                <InputNumber
                                  placeholder="Please Enter Amount"
                                  style={{ width: "100%" }}
                                  min={0}
                                  max={selectedPaymentMode === "INTOPUP" ? 25000 : undefined}
                                  type="number"
                                  value={amount}
                                  onChange={(e) => handleAmountChange(e)}
                                />
                              </Form.Item>
                            </Col>
                            {selectedPaymentMode === "INTOPUP" ?
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item
                                  label="Deposit Type"
                                  name="DepositType"
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    placeholder="Payment Mode"

                                    onChange={(e) => {
                                      handlePG(e);
                                    }}
                                  > <Option value="1">
                                      {/* Debit Card */}
                                      Net Banking
                                    </Option>
                                    <Option value="2">
                                      {/* Credit Card */}
                                      Wallets
                                    </Option>
                                    <Option value="3">
                                      {/* Net Banking */}
                                      Card
                                    </Option>
                                    <Option value="4">
                                      UPI
                                    </Option>
                                    <Option value="5">
                                      EMI
                                    </Option>

                                    {/* {pgList.map((data, index) => (
                                      <Option key={index} value={data.PaymentTypeID}>
                                        {getPaymentType(data.PaymentType)}
                                      </Option>
                                    ))} */}


                                  </Select>
                                </Form.Item>
                              </Col> : null
                            }



                            {/* <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                label="Payment Mode"
                                name="paymentMode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Payment Mode",
                                  },
                                ]}
                              >
                                <Select placeholder="Please select Payment Mode"
                                  onChange={handlePaymentModeChange}
                                >
                                  {/* <Option value="VirtualCash">
                                      VirtualCash
                                    </Option>
                                    <Option value="Cheque ">Cheque </Option>
                                    <Option value="Transfers">Transfers</Option>
                                    <Option value="Imps">Imps</Option>
                                    <Option value="Neft">Neft</Option>
                                    <Option value="Credit ">Credit </Option> *
                                  <Option value="CashDepositATM">
                                    Cash Deposit ATM
                                  </Option>
                                  <Option value="CashDepositBank">
                                    Cash Deposit Bank
                                  </Option>
                                  <Option value="DirectNetTransfer">
                                    Direct NetTransfer
                                  </Option>
                                  <Option value="GooglePayTransfer">
                                    GooglePay Transfer
                                  </Option>
                                  <Option value="PhonePayTransfer">
                                    PhonePay Transfer
                                  </Option>
                                  <Option value="AmazonPayTransfer">
                                    AmazonPay Transfer
                                  </Option>
                                  <Option value="RTGS">
                                    RTGS (Real-Time Gross Settlement)
                                  </Option>
                                  <Option value="UPI">
                                    UPI (Unified Payments Interface)
                                  </Option>
                                  <Option value="AMC">
                                    AMC (Annual Maintenance Charges)
                                  </Option>
                                  <Option value="BusCancelRefund">
                                    Bus Cancel Refund
                                  </Option>
                                  <Option value="CancellationRefund">
                                    Cancellation Refund
                                  </Option>
                                  <Option value="INTOPUP">
                                    INSTANT TOP-UP
                                  </Option>
                                  {/* <Option value="GSTCredit">
                                    GSTCredit
                                  </Option>
                                  <Option value="TDSCredit">
                                    TDSCredit
                                  </Option> *
                                </Select>
                              </Form.Item>
                            </Col> */}
                            {selectedPaymentMode != "INTOPUP" ?
                              <Col md={12} sm={12} xs={24}>
                                <Form.Item
                                  label="Deposit Date"
                                  name="depositeDate"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Deposit Date",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    placeholder="Please Enter Deposit Date"
                                    style={{ width: "100%" }}
                                  // disabledDate={disabledFromDate}
                                  />
                                </Form.Item>
                              </Col> : null}
                            {selectedPaymentMode != "INTOPUP" ?
                              <Col md={12} sm={12} xs={24}>
                                <Form.Item
                                  label="Transaction Id"
                                  name="transactionId"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Transaction Id",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Please Enter Transaction Id" />
                                </Form.Item>
                              </Col> : null}
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Remarks"
                                name="remarks"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Remarks",
                                  },
                                ]}
                              >
                                <Input placeholder="Please Enter Remarks" />
                              </Form.Item>
                            </Col>
                            <Col md={4} sm={12} xs={24} style={{ paddingTop: "4px" }}>
                              <Button
                                key="add"
                                type="primary"
                                htmlType="submit"
                                loading={isDepositeSubmitLoading} // Antd's loading prop to show spinner in button
                              >
                                {isDepositeSubmitLoading ? "Submitting..." : "Submit"}
                              </Button>
                            </Col>

                          </Row>
                        </Form>

                        {/* </div> */}
                      </Card>
                      {selectedPaymentMode === "INTOPUP" ?
                        <div className="payment-info-topUp">
                          <div className="payment-det-Insta_TopUp">
                            <h5 className="payment-header">Payment Details:</h5>
                            <div className="payment-details">
                              <div className="payment-row">
                                <p className="label">Amount</p>
                                <p className="value">: Rs. {amount}</p>
                              </div>
                              <div className="payment-row">
                                <p className="label">
                                  TDR Charges ({Pgselecteddata?.type !== "percentage" ? "Rs. " : ""}
                                  {Pgselecteddata?.amount}
                                  {Pgselecteddata?.type === "percentage" ? " %" : " "})
                                </p>
                                <p className="value">: Rs. {Number(tdrAmount).toFixed(2)}</p>
                              </div>
                              {tdrAmount > 0 &&
                                <div className="payment-row">
                                  <p className="label">
                                    GST on TDR (18%)

                                  </p>
                                  {/* <p className="value">: Rs. {Number(tdrAmount * 0.18).toFixed(2)}</p> */}
                                  <p className="value">: Rs. {Number(tdrGST).toFixed(2)}</p>
                                </div>
                              }
                              <div className="payment-row">
                                <p className="label">Total Transaction Amount</p>
                                <p className="value" style={{ fontWeight: "600" }}>: <span style={{ color: "red" }}>Rs</span> {totalAmount}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        : null}
                    </div>
                  </Panel>
                  <Panel header="Deposit Reports" key="2">
                    <div className="card-bt-gap">
                      <Card bordered={false}>
                        <div className="card-add-heading1">
                          <Form
                            layout="vertical"
                            form={searchForm}
                            onFinish={searchReportsForm}
                          // initialValues={{
                          //   userId: "",
                          //   fromDate: "",
                          //   toDate: "",
                          //   approvalStatus: "",
                          //   page: 0,
                          // }}
                          >
                            <Row gutter={12} align="middle">
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="From Date" name="fromDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    placeholder="Please Enter From Date"
                                    style={{ width: "100%" }}

                                  />
                                </Form.Item>
                              </Col>
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="To Date" name="toDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    placeholder="Please Enter To Date"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="Status" name="approvalStatus">
                                  <Select placeholder="Please select Status">
                                    <Option value="Pending">Pending</Option>
                                    <Option value="Rejected ">Rejected </Option>
                                    <Option value="Revoked">Revoked</Option>
                                    <Option value="Approved">Approved</Option>
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col span={2}>
                                <Button
                                  key="add"
                                  type="primary"
                                  htmlType="submit"
                                >
                                  Search
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                          {reportsList.length > 0 ? (
                            <>
                              <div className="card-add-heading">
                                <div className="rows-count">
                                  <h5>View Deposit Reports</h5>
                                  <p>{tableList.length} rows found !</p>
                                </div>
                                <div className="action-images">
                                  {showSearchBox && (
                                    <Input
                                      autocomplete="newpassword"
                                      placeholder="Search"
                                      onChange={(e) => searchTableData(e)}
                                      suffix={closeSearchInput}
                                      style={{
                                        padding: "0px 12px",
                                        maxWidth: "250px",
                                      }}
                                    />
                                  )}
                                  &nbsp;&nbsp;
                                  <img
                                    src={search}
                                    alt="search"
                                    onClick={() => searchData()}
                                  />
                                  <img
                                    src={excel}
                                    alt="excel"
                                    onClick={() => handleExcel()}
                                  />
                                  <img
                                    src={exchange}
                                    alt="exchange"
                                    onClick={() => {
                                      setTableList([]);
                                      getReportsList(staticObj);
                                    }}
                                  />
                                  {/* <p className="add-deposit-icon" onClick={handleModal}>
                    <PlusCircleFilled />
                  </p> */}
                                </div>
                              </div>

                              <Table
                                scroll={{ x: true }}
                                bordered
                                rowKey={uniqueKey}
                                dataSource={tableList}
                                columns={columns}
                                pagination={{
                                  defaultPageSize: 25,
                                  showSizeChanger: true,
                                  pageSizeOptions: ["25", "50", "100", "125"],
                                }}
                              />
                            </>
                          ) : null}
                        </div>
                      </Card>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Content>
        {/* {showcash ? <>
          <CashfreePayment val={Val} user={user} totalAmount={totalAmount} />
        </> : null} */}
      </div>
      {
        isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
      }
      {
        openCashfreeNewVersionCheckout && (
          <CashFreeNewVersionCheckout
            pgData={pgData}
          // loadingSpin={loadingSpin}
          />
        )
      }
    </Layout >

  );
};
export default Deposits;
